import React, { useEffect } from "react"
// import { Helmet } from 'react-helmet';
import Layout from "../components/layout";
import Hero from "../images/hero-subs.jpg"
import Link from 'gatsby-link';
import SEO from "../components/seo";
import Revs from "../components/reviews";
import PricingTable from "../components/pricing-table"


const SubscriptionsPage = () => {
	useEffect(() => {
	document.getElementById('lang-switch').href="/pl/subskrypcje/"
	}, []);
	return (
	<>
	<SEO
		title={ 'Shroomscriptions' }
		header={ { siteTitle: ' Plans | Shop' } }
	/>
	<Layout>
	<div className="container-fluid" id="shroomscriptions" style={{
			padding:'0',
			minHeight: '1200px',
			backgroundImage: `url(${Hero})`,
			backgroundSize:'cover',
			backgroundColor:'#9FD4F0',
			marginTop:'-30px',
			backgroundAttachment:'fixed',
			backgroundPosition:'bottom center',
			backgroundRepeat:'no-repeat',
		}}>
	<div className="container has-text-centered" style={{textAlign:'center',paddingTop:'30px'}}>
		<h1 id="shrooms1" className="mt-5 mb-5 display-5 spec-h text-white">Shroomscriptions</h1>
		<div style={{
			background: 'whitesmoke',
			opacity: '0.95',
			borderRadius:'20px 20px 0 0',
			padding:'2em',
		}}>
		<h2 className="mt-5 mb-5 display-10 spec-h">Elevate Your Well-being with <br /> Exclusive shroom Subscription plans!</h2>
		<h3 className="mt-5 mb-5 display-10 spec-h">✨ Learn more about our 3 subscription plans! ✨</h3>
		</div>
		<PricingTable />
		<div style={{
			background: 'whitesmoke',
			opacity: '0.9',
			padding:'2em',
		}}>
		<h4 className="display-10 spec-h">Transform your daily routine and prioritize your well-being with our Shroom
		Subscription options. Subscribe now and embark on a journey towards a healthier, more energized you! 🌟</h4>
				<h5 className="mt-5 mb-2 spec-h">✨  Why Choose Our shroom Subscriptions? ✨</h5>
				<p style={{textAlign:'left',color:'#1D1D1D'}}>
			🍄 <b>Special Ingredients</b>:<br /> Our formulations include the powerful duo of cordyceps
			and lion's mane, known for their adaptogenic and cognitive-boosting properties.
		<br /><br />
			🌿 <b>Health Benefits</b>:<br /> Enjoy a natural and healthy way to enhance your energy, focus,
			and relaxation without artificial additives.
		<br /><br />
			🚚 <b>Convenient Delivery</b>:<br /> Receive your monthly supply of shroom bottles conveniently
			delivered to your doorstep, hassle-free.
		</p>
		</div>
		<div style={{
			background: '#FFD36D',
			opacity: '0.9',
			padding:'20px',
		}}>
		<h3 className="mt-2 mb-2 display-10 spec-h">🚀 Subscription – Power x 24</h3>
		<p className="text-black" style={{textAlign:'left'}}>
Unleash the power within with our premium Shroom Power blend! Packed with special
 ingredients like cordyceps and lion's mane, each bottle is a potent elixir designed
  to boost your energy and focus. Subscribe now for just PLN 269 per month and enjoy
	 the benefits of 24 Power-packed bottles delivered straight to your door.
	 Fuel your days with vitality and conquer challenges effortlessly.</p>
		</div>
		<div style={{
			background: 'pink',
			opacity: '0.9',
			padding:'20px',
		}}>
	<h3 className="mt-2 mb-2 display-10 spec-h">	🌈 Subscription – Mix 12 + 12 </h3>
		<p className="text-black" style={{textAlign:'left'}}>
Experience the best of both worlds with our Mix 12 + 12 subscription!
Indulge in a harmonious blend of 12 Relax and 12 Power shroom bottles per month,
 carefully curated to balance your mind and body. Infused with the unique benefits
  of cordyceps and lion's mane, this subscription is your ticket to a holistic well-being
	 journey. For only PLN 269 per month, embark on a path of relaxation and renewed energy
	  with this perfectly balanced mix.</p>
		</div>
		<div style={{
			background: 'lightblue',
			opacity: '0.9',
			padding:'20px',
		}}>
		<h3 className="mt-2 mb-2 display-10 spec-h">😌 Subscription – Relax x 24 </h3>
		<p className="text-black" style={{textAlign:'left'}}>
Embrace tranquility with our Shroom Relax subscription! Immerse yourself in the
soothing effects of 24 Relax shroom bottles per month, enriched with cordyceps
and lion's mane for a calming experience like no other. Unwind from the stress
 of everyday life and prioritize your mental well-being. Subscribe today for
 just PLN 269 per month and make relaxation a part of your routine.</p>
		</div>
		<div style={{
			background: 'whitesmoke',
			opacity: '0.9',
			padding:'2em',
			borderRadius:'0px 0px 20px 20px',
		}}>
		<p style={{textAlign:'center'}}>
		<Link to="/" className="btn btn-outline-dark mush">go back</Link>
		</p>
		</div>
	</div>
	<div style={{marginTop:'200px'}}>
	<Revs />
	</div>

	</div>
	</Layout>
	</>
)
}

export default SubscriptionsPage
